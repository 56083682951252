const hexToRgb = (hex: string) => {
  // Remove the '#' if it exists
  const cleanHex = hex.replace("#", "");

  // Parse the r, g, b values
  const bigint = parseInt(cleanHex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return { r, g, b };
};

// Utility function to convert RGB back to hex
const rgbToHex = (r: number, g: number, b: number) => {
  const toHex = (component: number) => component.toString(16).padStart(2, "0");
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

// Function to lighten a hex color by a given percentage
const lightenHexColor = (hex: string, percent: number) => {
  const { r, g, b } = hexToRgb(hex);

  // Increase RGB values by the given percentage, ensuring values stay within the 0-255 range
  const newR = Math.min(255, Math.floor(r + (255 - r) * (percent / 100)));
  const newG = Math.min(255, Math.floor(g + (255 - g) * (percent / 100)));
  const newB = Math.min(255, Math.floor(b + (255 - b) * (percent / 100)));

  return rgbToHex(newR, newG, newB);
};

const colors = { lightenHexColor };

export default colors;
