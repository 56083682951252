import ChatService from "../../front-services/ChatService";
import Colors from "../../utils/Colors";

const chatService = new ChatService();
export default function HandoffForm({
  color,
  setIsHandoffFormFullScreen,
  threadId,
  assistantId,
}: any) {
  const handleFormSubmit = (event: any) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    const formValues = {
      firstName: formData.get("first-name"),
      lastName: formData.get("last-name"),
      email: formData.get("email"),
    };

    const userMessage = `Nome: ${formValues.firstName}, Cognome: ${formValues.lastName}, Email: ${formValues.email}`;

    chatService
      .sendMessageWithoutReply(userMessage, assistantId, threadId)
      .then(() => {
        setIsHandoffFormFullScreen(false);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Colors.lightenHexColor(color, 50),
        height: "100%",
        width: "100%",
      }}
    >
      <button
        className="custom-fullscreen-button"
        style={{ backgroundColor: color }}
        onClick={() => setIsHandoffFormFullScreen(false)}
      >
        <i className="fa-solid fa-x"></i>
      </button>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          width: "70%",
        }}
        onSubmit={handleFormSubmit}
      >
        <input
          type="text"
          placeholder="Nome"
          className="form-input"
          name="first-name"
        />
        <input
          type="text"
          placeholder="Cognome"
          className="form-input"
          name="last-name"
        />
        <input
          type="text"
          placeholder="Email"
          className="form-input"
          name="email"
        />
        <button
          type="submit"
          className="form-button"
          style={{ backgroundColor: color }}
        >
          Invia richiesta
        </button>
      </form>
    </div>
  );
}
