import { useEffect, useState, useRef } from "react";
import ChatService from "../front-services/ChatService";
import Colors from "../utils/Colors";
import ReactMarkdown from "react-markdown";
import WideImageViewer from "../components/WideImageViewer";
import constants from "../utils/Constants";
import HandoffForm from "../components/Handoff/HandoffForm";
import HandoffButton from "../components/Handoff/HandoffButton";

export interface ChatWidgetStyle {
  color: string;
  name: string;
  fileUrl: string;
  loaded: boolean;
}

const chatService = new ChatService();

const Chat = ({ startOpen = false, isMobile }: any) => {
  const [isOpen, setIsOpen] = useState(startOpen);
  const [isClosing, setIsClosing] = useState(false);
  const [messages, setMessages]: any = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [assistant, setAssistant]: any = useState({ name: null, id: null });
  const [isEditor, setIsEditor] = useState(false);
  const chatMessagesRef: any = useRef(null);
  const chatContainerRef: any = useRef(null);
  const [isMessageLoading, setIsMessageLoading] = useState(false);
  const [threadId, setThreadId]: any = useState(null);
  const [toolName, setToolName] = useState(null);

  const [isWIVFullScreen, setWIVIsFullScreen] = useState(false);
  const [WIVfullScreenImage, setWIVFullScreenImage] = useState<any>(null);

  const [isHandoffFormFullScreen, setIsHandoffFormFullScreen] = useState(false);
  const [isAnyFullScreen, setIsAnyFullScreen] = useState(false);

  const [style, setStyle] = useState<ChatWidgetStyle>({
    color: "#a72eff",
    name: "Nome Assistente",
    fileUrl: "https://plutoniosolutions.com/assets/img/SmallCircle.webp",
    loaded: false,
  });

  const [assistantChatConfiguration, setAssistantChatConfiguration]: any =
    useState({ loaded: false });

  useEffect(() => {
    setIsAnyFullScreen(isWIVFullScreen || isHandoffFormFullScreen);
  }, [isHandoffFormFullScreen, isWIVFullScreen]);

  useEffect(() => {
    const updateDimensions = () => {
      const width =
        isMobile && isOpen
          ? constants.DIMENSIONS.MOBILE.OPEN.WIDTH_STRING
          : isOpen
          ? constants.DIMENSIONS.DESKTOP.OPEN.WIDTH + 100
          : constants.DIMENSIONS.DESKTOP.CLOSED.WIDTH;
      const height =
        isMobile && isOpen
          ? constants.DIMENSIONS.MOBILE.OPEN.HEIGHT_STRING
          : isOpen
          ? constants.DIMENSIONS.DESKTOP.OPEN.HEIGHT + 100
          : constants.DIMENSIONS.DESKTOP.CLOSED.HEIGHT;

      // Send the dimensions to the parent window
      window.parent.postMessage({ width, height }, "*");
    };
    console.log("isMobile:", isMobile);
    updateDimensions();
    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, [isOpen, isMobile]);

  useEffect(() => {
    const updateDimensions = () => {
      const width =
        isMobile && isOpen
          ? constants.DIMENSIONS.MOBILE.OPEN.WIDTH_STRING
          : isOpen
          ? constants.DIMENSIONS.DESKTOP.OPEN.WIDTH + 100
          : constants.DIMENSIONS.DESKTOP.CLOSED.WIDTH;
      const height =
        isMobile && isOpen
          ? constants.DIMENSIONS.MOBILE.OPEN.HEIGHT_STRING
          : isOpen
          ? constants.DIMENSIONS.DESKTOP.OPEN.HEIGHT + 100
          : constants.DIMENSIONS.DESKTOP.CLOSED.HEIGHT;
      // Send the dimensions to the parent window
      window.parent.postMessage({ width, height }, "*");
    };
    updateDimensions();

    window.addEventListener("onload", updateDimensions);

    return () => {
      window.removeEventListener("onload", updateDimensions);
    };
  }, [isOpen, isMobile]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const assistant_id = urlParams.get("a_id");
    const assistant_name = urlParams.get("a_name");

    const isEditor = urlParams.get("editor");

    if (isEditor) {
      setIsEditor(true);
      setIsOpen(true);

      return;
    }

    if (assistant_id) {
      setAssistant({ name: assistant_name, id: assistant_id });

      chatService
        .getAssistantChatConfiguration(assistant_id)
        .then((response) => {
          if (response.ok) {
            response.json().then((data) => {
              setAssistantChatConfiguration({ ...data, loaded: true });
            });
          }
        });

      chatService.getWidgetConfiguration(assistant_id).then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            const resStyle = data.configuration.style;
            setStyle({
              color: resStyle.mainColor,
              name: resStyle.name,
              fileUrl: resStyle.fileUrl,
              loaded: true,
            });
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    // Scroll to the bottom of the chat messages when a new message is added
    chatMessagesRef.current?.scrollTo(0, chatMessagesRef.current.scrollHeight);
  }, [messages]);

  const toggleChatWidget = () => {
    if (isOpen) {
      // Start closing animation
      setIsClosing(true);
      // After 400ms (duration of animation), actually close the widget
      setTimeout(() => {
        setIsClosing(false);
        setIsOpen(false); // Fully close the widget
      }, 400); // This duration should match the CSS transition duration
    } else {
      setIsOpen(true); // Immediately open the widget
    }
  };

  const handleInputChange = (event: any) => {
    setInputMessage(event.target.value);
  };

  const getTime = () => {
    const date = new Date();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const [welcomeMessageTime] = useState(getTime());
  function generateUniqueId() {
    return Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
  }

  const handleUserMessageStream = async () => { 
    if (inputMessage.trim() === "") return;

    setMessages((prevMessages: any) => [
      ...prevMessages,
      { type: "user", content: inputMessage, time: getTime() },
    ]);

    setInputMessage("");

    setIsMessageLoading(true);
    await sendMessageStream(inputMessage);
     
  };
  
  const mapTool = {
    hotel_info: "Cercando informazioni sull'hotel...",
    generate_quote: "Preparando un preventivo...",
    check_availability: "Controllo disponibilità...",
    print_calendar: "Controllo calendario...",
    nearby_restaurants: "Cercando ristoranti nelle vicinanze...",
    nearby_attractions: "Cercando attrazioni nelle vicinanze...",
    human_handoff: "Collegamento con un operatore...",
  }

  const sendMessageStream = (inputMessage:String) => {
    return new Promise((resolve, reject) => {            
      let baseUrl = process.env.REACT_APP_IS_PROD !== "yes" ? process.env.REACT_APP_DENGINE_API_DEV : process.env.REACT_APP_DENGINE_API_PROD;            
      const eventSource = new EventSource(`${baseUrl}/stream?user_message=${inputMessage}&drastik_assistant_id=${ assistant.id}&thread_id=${threadId}`);

      let botResponse = {
        messageReply: "",
        metadata: null,
      };
      
      let id = generateUniqueId();

      eventSource.onmessage = (event) => {        
        try {
          const data = JSON.parse(event.data);
          let eventType = data.event.type;        
          console.log('Received event:', eventType, data);

          if (eventType === 'event.stream.chunk') {      
            setIsMessageLoading(false);    
            setThreadId(data.thread_id);
            botResponse.messageReply += data.event.content;                 
            setMessages((prevMessages:any) => {                                    
              const updatedMessages = [...prevMessages];
              const lastIndex = updatedMessages.length - 1;
              const lastMessage = updatedMessages[lastIndex];
              console.log('lastMessage:', lastMessage);

              if (lastMessage && lastMessage.type === 'bot') {              
                updatedMessages[lastIndex] = {
                  ...lastMessage,
                  content: botResponse.messageReply,
                };                
              } else {              
                const newMessage = {
                  id: id,
                  type: 'bot',
                  content: botResponse.messageReply,
                  metadata: botResponse.metadata,
                  time: getTime(),
                };
                updatedMessages.push(newMessage);                
              }
              return updatedMessages;
            });
          } 
          else if (eventType === 'event.tool.start') {
            let tool_name = data.event.tool_name;           
            setToolName(tool_name);            
          }
          else if (eventType === 'event.stream.reply.completed') {               
            let metadata = data.metadata;
            setMessages((prevMessages:any) => {                                    
              const updatedMessages = [...prevMessages];
              const lastIndex = updatedMessages.length - 1;
              const lastMessage = updatedMessages[lastIndex];              

              if (lastMessage && lastMessage.type === 'bot') {              
                updatedMessages[lastIndex] = {
                  ...lastMessage,
                  metadata: metadata,
                };                
              } 
              return updatedMessages;
            });
            setToolName(null);
            //eventSource.close();
            resolve(botResponse);
          } 
          else if (eventType === 'event.stream.error') { 
            botResponse.messageReply += data.event.error;                
            setMessages((prevMessages:any) => {                                    
              const updatedMessages = [...prevMessages];
                            
                const newMessage = {
                  id: id,
                  type: 'bot',
                  content: botResponse.messageReply,
                  metadata: botResponse.metadata,
                  time: getTime(),
                };
                updatedMessages.push(newMessage);                
              
              return updatedMessages;
            });
            setIsMessageLoading(false);       
            eventSource.close();
            resolve(data.response);
          }
        } catch (error) {
          console.error('Error in eventSource.onmessage:', error);
        }
      };

      eventSource.onerror = (err) => {
        console.error('EventSource failed:', err);
        eventSource.close();
      };
    });
  };

  const handleShortcutClick = async (userMessage: any) => {
    setMessages((prevMessages: any) => [
      ...prevMessages,
      { type: "user", content: userMessage, time: getTime() },
    ]);
    setInputMessage("");
    setIsMessageLoading(true);
    await sendMessageStream(userMessage);
  };

  const CustomLinkRenderer = ({ href, children }: any) => {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  };

  useEffect(() => {
    const adjustForKeyboard = () => {
      const viewportHeight = window.innerHeight;
      const widgetFooter = document.querySelector(
        ".widget-chat-footer"
      ) as HTMLElement;
      if (widgetFooter) {
        widgetFooter.style.bottom = `${window.outerHeight - viewportHeight}px`; // Adjust based on remaining height
      }
    };

    window.addEventListener("resize", adjustForKeyboard);

    return () => {
      window.removeEventListener("resize", adjustForKeyboard);
    };
  }, []);

  useEffect(() => {
    chatService.ping();
  }, []);

  //editor
  useEffect(() => {
    function handleMessage(event: any) {
      const data = event.data as ChatWidgetStyle;
      if (data.color) setStyle(data);
    }
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <>
      {style.loaded && assistantChatConfiguration.loaded && (
        <div
          className="widget-chat-button p-1 w-14 h-14 rounded-full shadow-lg flex items-center justify-center text-white text-3xl focus:outline-none hover:bg-blue-600 transition-colors duration-200 ease-in-out relative"
          style={{
            backgroundColor: style.color,
            boxShadow: `0 4px 6px -1px ${style.color}, 0 2px 4px -1px ${style.color}`,
            opacity: !isOpen || !isClosing ? "1" : "0",
            transition: "opacity 0.15s ease-in-out",
            position: "fixed",
            bottom: isMobile ? "20px" : "50px",
            right: isMobile ? "20px" : "50px",
            width: isMobile ? "60px" : "80px",
            height: isMobile ? "60px" : "80px",
            color: "#fff",
            border: "none",
            borderRadius: "50%",
            textAlign: "center",
            lineHeight: "60px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={!isEditor ? toggleChatWidget : undefined}
        >
          <i
            className="fas fa-comments chat-icon"
            style={{ fontSize: isMobile ? "25px" : "38px" }}
          ></i>

          {assistantChatConfiguration &&
            assistantChatConfiguration.welcomeMessage && (
              <div className="chat-tooltip">
                <div className="chat-tooltip-text">
                  {assistantChatConfiguration.welcomeMessage}
                </div>
                <div className="chat-tooltip-text">
                  Hai ricevuto un messaggio!
                </div>
              </div>
            )}

          {/* Pulse Effect */}
          <div
            className="pulse-effect"
            style={{
              position: "absolute",
              bottom: "0px", // Adjust as needed
              left: "0px",
              width: isMobile ? "12px" : "15px",
              height: isMobile ? "12px" : "15px",
              borderRadius: "50%",
              backgroundColor: "rgba(38, 178, 11)", // Use RGBA for background
              border: "2px solid white",
              boxShadow: "0 0 0 0 rgba(38, 178, 11, 0.4)",
              animation: "pulse 2s infinite",
            }}
          ></div>
        </div>
      )}

      <div
        ref={chatContainerRef}
        style={{
          position: "fixed",
          right: isMobile ? "0px" : "20px",
          width: isMobile
            ? constants.DIMENSIONS.MOBILE.OPEN.WIDTH_STRING
            : constants.DIMENSIONS.DESKTOP.OPEN.WIDTH_STRING,
          bottom: isMobile ? "0px" : "20px",
          height: isMobile
            ? constants.DIMENSIONS.MOBILE.OPEN.HEIGHT_STRING
            : constants.DIMENSIONS.DESKTOP.OPEN.HEIGHT_STRING,
          maxHeight: isClosing
            ? "0px" // When it's closing, the max-height should go to 0
            : isOpen // When it's open, use the appropriate max-height for open state
            ? isMobile
              ? constants.DIMENSIONS.MOBILE.OPEN.HEIGHT_STRING // Mobile open state
              : constants.DIMENSIONS.DESKTOP.OPEN.HEIGHT_STRING // Desktop open state
            : "0",
          border: `1px solid ${style.color}`,
          boxShadow: `0 2px 6px -1px ${style.color}, 0 2px 2px -1px ${style.color}`,
          padding: "0px",
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          borderRadius: isMobile ? "0px" : "30px",
          overflow: "hidden",
          opacity: isOpen || isClosing ? "1" : "0",
          transition:
            "max-height 0.4s ease-in-out, opacity 0.4s ease-in-out, border 0.4s ease-in-out", // Use 'ease-in-out' for smoother transition
        }}
      >
        {isWIVFullScreen && (
          <WideImageViewer
            image={WIVfullScreenImage}
            maxHeight={
              isMobile
                ? undefined
                : constants.DIMENSIONS.DESKTOP.OPEN.HEIGHT_STRING
            }
            maxWidth={
              isMobile
                ? undefined
                : constants.DIMENSIONS.DESKTOP.OPEN.WIDTH_STRING
            }
            setFullScreen={setWIVIsFullScreen}
            isFullScreen={isWIVFullScreen}
            setFullScreenImage={setWIVFullScreenImage}
          />
        )}

        {isHandoffFormFullScreen && (
          <HandoffForm
            color={style.color}
            threadId={threadId}
            assistantId={assistant.id}
            setIsHandoffFormFullScreen={setIsHandoffFormFullScreen}
          />
        )}

        {!isAnyFullScreen && (
          <>
            {" "}
            <div
              className="widget-header"
              style={{ backgroundColor: style.color, borderColor: style.color }}
            >
              <div style={{ display: "flex" }}>
                <img
                  src={style.fileUrl}
                  style={{ borderRadius: "50%" }}
                  alt="Assistant Icon"
                  className="header-icon inline-block"
                />
                <div className="header-title-description">
                  <span className="text-lg font-semibold ml-2 widget-header-title">
                    <b>{style.name}</b>
                  </span>
                  <span className="header-description">
                    Replies in real-time
                  </span>
                </div>
              </div>

              <button
                className="minimize-button"
                style={{
                  backgroundColor: Colors.lightenHexColor(style.color, 35),
                }}
                onClick={toggleChatWidget}
                disabled={isEditor}
              >
                <i className="fa-solid fa-x"></i>
              </button>
            </div>
            <div className="widget-chat-messages" ref={chatMessagesRef}>
              {assistantChatConfiguration &&
                assistantChatConfiguration.welcomeMessage && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        marginBottom: "10px",
                        backgroundColor: Colors.lightenHexColor(
                          style.color,
                          60
                        ),
                      }}
                      className="widget-chatbot-message"
                    >
                      <ReactMarkdown>
                        {assistantChatConfiguration.welcomeMessage}
                      </ReactMarkdown>
                      {assistantChatConfiguration.shortcutButtons && (
                        <div className="shortcut-buttons">
                          {assistantChatConfiguration.shortcutButtons.map(
                            (sbutton: any, index: any) => (
                              <button
                                className="shortcut-button"
                                style={{
                                  color: Colors.lightenHexColor(
                                    style.color,
                                    10
                                  ),
                                  boxShadow: `0 2px 6px -1px ${style.color}, 0 2px 2px -1px ${style.color}`,
                                }}
                                key={index}
                                onClick={() =>
                                  handleShortcutClick(sbutton.userMessage)
                                }
                              >
                                {sbutton.label}
                              </button>
                            )
                          )}
                        </div>
                      )}
                      <p className="message-time">{welcomeMessageTime}</p>
                    </div>
                  </div>
                )}
              {messages.map((message: any, index: any) => (
                <div
                  key={index}
                  style={{
                    marginBottom: "10px",
                    backgroundColor:
                      message.type !== "user"
                        ? Colors.lightenHexColor(style.color, 60)
                        : Colors.lightenHexColor(style.color, 85),
                  }}
                  className={
                    message.type === "user"
                      ? "widget-user-message"
                      : "widget-chatbot-message"
                  }
                >
                  <ReactMarkdown
                    components={{
                      a: CustomLinkRenderer, // Override how links are rendered
                    }}
                  >
                    {message.content}
                  </ReactMarkdown>
                  {message.metadata &&
                    message.metadata.map((meta: any, index: any) => {
                      switch (meta.type) {
                        case "image": {
                          if (meta.value.startsWith("https://i.ibb.co/")) {
                            return (
                              <div className="message-attachment">
                                <WideImageViewer
                                  image={meta.value}
                                  maxHeight={
                                    isMobile
                                      ? constants.DIMENSIONS.MOBILE.OPEN
                                          .HEIGHT_STRING
                                      : constants.DIMENSIONS.DESKTOP.OPEN
                                          .HEIGHT_STRING
                                  }
                                  maxWidth={
                                    isMobile
                                      ? constants.DIMENSIONS.MOBILE.OPEN
                                          .WIDTH_STRING
                                      : constants.DIMENSIONS.DESKTOP.OPEN
                                          .WIDTH_STRING
                                  }
                                  setFullScreen={setWIVIsFullScreen}
                                  isFullScreen={isWIVFullScreen}
                                  setFullScreenImage={setWIVFullScreenImage}
                                />
                              </div>
                            );
                          } else {
                            return (
                              <img
                                key={index}
                                src={meta.value}
                                alt="immagine"
                                className="message-attachment"
                              />
                            );
                          }
                        }
                        case "human_handoff_form": {
                          return (
                            <HandoffButton
                              setHandoffFormFullscreen={
                                setIsHandoffFormFullScreen
                              }
                              label={"Parla con un operatore"}
                              style={style}
                            />
                          );
                        }
                      }

                      return <></>;
                    })}
                  <p className="message-time">{message.time}</p>
                </div>
              ))}
              {isMessageLoading && (
                
                <div
                  className="widget-chatbot-message"
                  style={{
                    backgroundColor: Colors.lightenHexColor(style.color, 60),
                  }}
                >
                  <span
                    className="loader-container"
                    style={{
                      backgroundColor: Colors.lightenHexColor(style.color, 60),
                    }}
                  >
                     {toolName && mapTool[toolName]} 
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                  </span>
                </div>
              )}
            </div>
            <div
              className="widget-chat-footer"
              style={{
                backgroundColor: style.color,
              }}
            >
              <div className="widget-chat-input-container border border-grey-400">
                <textarea
                  onClick={() => {
                    if (isMobile)
                      chatContainerRef.current?.scrollTo(
                        0,
                        chatContainerRef.current.scrollHeight
                      );
                  }}
                  className="widget-chat-input"
                  disabled={isEditor}
                  style={{ fontFamily: "roboto, sans-serif", fontSize: "15px" }}
                  placeholder="Type a message..."
                  value={inputMessage}
                  onChange={handleInputChange}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      handleUserMessageStream();
                    }
                  }}
                />
                <button
                  disabled={isEditor || isMessageLoading}
                  className="widget-send-button flex-none m-0 p-0 text-white"
                  style={{ backgroundColor: style.color }}
                  onClick={handleUserMessageStream}
                >
                  <i
                    className="fa-regular fa-paper-plane"
                    style={{ color: "whitesmoke", fontSize: "16px" }}
                  ></i>
                </button>
              </div>
              <div className="powered-by">
                <span
                  style={{
                    fontSize: "10px",
                    color: "white",
                    marginRight: "3px",
                  }}
                >
                  Powered By
                </span>
                <a
                  href="https://plutoniosolutions.com/"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    fontSize: "12px",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  PlutoniosSolutions
                  <img
                    src="https://plutoniosolutions.com/assets/img/SmallCircle.webp"
                    alt="Plutonios Solutions Logo"
                    className="inline-block mr-1"
                    style={{
                      width: "10px",
                      marginLeft: "2px",
                      verticalAlign: "baseline",
                      border: "1px solid white",
                      borderRadius: "50%",
                    }}
                  />
                </a>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Chat;
