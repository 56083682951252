import React, { useEffect, useState, useRef } from "react";
import { Pannellum } from "pannellum-react";

export default function WideImageViewer({
  image,
  maxWidth,
  maxHeight,
  isFullScreen,
  setFullScreen,
  setFullScreenImage,
}) {
  const [blobUrl, setBlobUrl] = useState(null);
  const pannellumRef = useRef(null); // Create a ref for the Pannellum component

  // Function to fetch the image and convert to Blob URL
  const convertImageToBlob = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      setBlobUrl(blobUrl);
    } catch (error) {
      console.error("Error converting image to Blob:", error);
    }
  };

  useEffect(() => {
    if (image) {
      convertImageToBlob(image);
    }
  }, [image]);

  // Function to toggle fullscreen
  const toggleFullScreen = () => {
    if (isFullScreen) {
      setFullScreen(false);
      setFullScreenImage(null);
    } else {
      setFullScreen(true);
      setFullScreenImage(image);
    }
  };

  return (
    <>
      {blobUrl && (
        <div
          className="pannellum-container"
          ref={pannellumRef}
          style={{
            height: isFullScreen ? maxHeight : 250,
            width: isFullScreen ? maxWidth : undefined,
          }}
        >
          <Pannellum
            image={blobUrl}
            pitch={10}
            yaw={120}
            hfov={110}
            autoLoad
            showZoomCtrl={false}
            // Optional: if you want to hide the default fullscreen button
            showFullscreenCtrl={false}
          />
          {isFullScreen ? (
            <button
              className="custom-fullscreen-button"
              onClick={toggleFullScreen}
            >
              <i class="fa-solid fa-x"></i>
            </button>
          ) : (
            <button
              className="custom-fullscreen-button"
              onClick={toggleFullScreen}
            >
              <i class="fa-solid fa-expand"></i>
            </button>
          )}
        </div>
      )}
    </>
  );
}
